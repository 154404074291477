<template>
  <div class="profile">
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      extended
      extension-height="90px"
      title="Update Profile"
      show-custom-icon
    >
      <template slot="extension">
        <v-card
          color="primary"
          flat
        >
          <div class="d-flex pa-2">
            <v-avatar
              class="avatar"
              size="56"
              style="font-size: 34px"
            >
              {{ this.profile.first_name.charAt(0).toUpperCase() }}
            </v-avatar>
            <div class="d-flex self-align-start flex-column">
              <span
                class="pl-4"
                style="font-size: 24px; line-height: 30px"
              >{{ this.profile.first_name }} {{ this.profile.last_name }}</span>
            </div>
          </div>
        </v-card>
      </template>
      <template slot="customIcon">
        <v-icon @click="saveInformation">
          done
        </v-icon>
      </template>
    </appBarMobile>
    <div class="ma-0">
      <v-col
        col="12"
        md="12" class="px-0"
      >
        <div class="personal-details mb-2">
          <h4 class="mb-3">
            Personal Details
          </h4>
          <v-select
            :items="titles"
            v-model="profile.title"
            label="Personal title"
            :rules="[rules.required]"
            aria-label="Select title"
            prepend-icon="person"
          />
          <v-text-field
            type="text"
            label="First Name"
            aria-label="First Name Field"
            class="pt-2 pb-0"
            readonly
            :value="profile.first_name"
            :rules="[
                        rules.required,
                        rules.alphaNumeric,
                        rules.lessThan255,
                        rules.noNumbers
                      ]"
            prepend-icon="person"
          />
          <v-text-field
            type="text" 
            label="Last Name"
            :rules="[
                        rules.required,
                        rules.alphaNumeric,
                        rules.lessThan255
                      ]"
            aria-label="Last Name Field"
            class="pt-2 pb-0"
            readonly
            :value="profile.last_name"
            prepend-icon="person"
          />
          <v-select
            :items="genders"
            readonly
            v-model="profile.gender"
            label="Gender"
            :rules="[rules.required, rules.lessThan126]"
            aria-label="Select Gender"
            prepend-icon="person"
          />
          <DatePicker
            v-if="displayDob"
            v-model="profile.dateOfBirth"
            :rules="[rules.required, rules.lessThan126]"
            label="Date of birth"
          />
          <v-text-field
            type="text"
            label="Preferred First Name"
            aria-label="Preferred First Name Field"
            class="pt-2 pb-0"
            :rules="preferNameRules"
            v-model="profile.preferredGivenName"
            prepend-icon="person"
          />
          <v-text-field
            type="text"
            label="Preferred Last Name"
            aria-label="Preferred Last Name Field"
            class="pt-2 pb-0"
            :rules="preferNameRules"
            v-model="profile.preferredSurname"
            prepend-icon="person"
          />
          <v-text-field
            type="text"
            label="Email Display Name / Email Tag"
            aria-label="Email Display Name / Email Tag"
            class="pt-2 pb-0"
            :rules="preferNameRules"
            v-model="profile.displayNameSuffix"
            prepend-icon="person"
          />
          <v-text-field
            type="text"
            label="Personal Mobile Number"
            aria-label="Personal Mobile Number Field"
            :rules="[rules.lessThan255, rules.phoneIsValid]"
            class="pt-2 pb-0"
            v-model="mainPhone.number"
            prepend-icon="phone"
          />
        </div>
        
        <div class="assistant-details mb-2">
          <h4 class="mb-3">
            Assistant Details
          </h4>
          <v-text-field
            type="text"
            label="Assistant Name"
            aria-label="Assistant Name Field"
            class="pt-2 pb-0"
            v-model="firstAssistant.name"
            prepend-icon="person"
            :rules="[rules.alphaNumeric, rules.lessThan255]"
          />
          <!-- not present in api
          <v-text-field
            type="text"
            label="Assistant Email"
            aria-label="Assistant Email Field"
            class="pt-2 pb-0"
            v-model="firstAssistant.email"
            prepend-icon="email"
          />
          -->
          <v-text-field
            type="text"
            label="Assistant Mobile"
            aria-label="Assistant Mobile Field"
            :rules="[rules.phoneIsValid, rules.lessThan255]"
            class="pt-2 pb-0"
            v-model="firstAssistant.phone.number"
            prepend-icon="phone_iphone"
          />
        </div>
      </v-col>
      <v-col col="12"
       md="12" class="pa-0"
      >
        <div class="work-contact-details mb-2">
          <h4 class="mb-2">
            Work Contact Details
          </h4>
          <v-text-field
            type="text"
            label="Business Title / Role"
            aria-label="Business Title / Role Field"
            class="pt-2 pb-0"
            v-model="profile.position_title"
            :rules="[
                        rules.alphaNumeric,
                        rules.lessThan126,
                        rules.noNumbers
                      ]"
            prepend-icon="work"
          />
          <!-- not included in api 
          <v-text-field
            type="text"
            label="Physical Site"
            aria-label="Physical Site Field"
            class="pt-2 pb-0"
          />
          -->
          <v-text-field
            type="text"
            label="Street Address"
            aria-label="Street Address Field"
            class="pt-2 pb-0"
            v-model="businessAddress.streetAddress"
            prepend-icon="place"
            :rules="[rules.lessThan255, rules.alphaNumeric]"
          />
          <v-text-field
            type="text"
            label="Suburb"
            aria-label="Suburb Field"
            class="pt-2 pb-0"
            v-model="businessAddress.locality"
            prepend-icon="place"
            :rules="[rules.lessThan255, rules.alphaNumeric]"
          />
          <v-text-field
            type="text"
            label="Post Code"
            aria-label="Post Code"
            class="pt-2 pb-0"
            v-model="businessAddress.postcode"
            prepend-icon="place"
            :rules="[rules.lessThan64, rules.onlyNumbers]"
          />
          <v-text-field
            type="text"
            label="Company"
            aria-label="Company Field"
            :rules="[rules.lessThan64, rules.alphaNumeric]"
            class="pt-2 pb-0"
            v-model="businessAddress.company"
            prepend-icon="business"
          />
          <v-text-field
            type="text"
            label="Department"
            aria-label="Department Field"
            class="pt-2 pb-0"
            v-model="businessAddress.department"
            :rules="[rules.lessThan64, rules.alphaNumeric]"
            prepend-icon="business"
          />
          <v-text-field
            type="text"
            label="Primary Contact Number"
            aria-label="Primary Contact Number Field"
            class="pt-2 pb-0"
            :rules="[rules.lessThan255, rules.phoneIsValid]"
            v-model="workPhone.number"
            prepend-icon="phone"
          />
          <!-- not included in api, removed
          <v-text-field    
            type="text"
            label="Secondary Contact Number"
            aria-label="Secondary Contact Number Field"
            class="pt-2 pb-0"
            v-model="secondaryPhone.number"
          />
          -->
        </div>

        <!--
        <div class="emergency-contact-details mb-5">
          <h4 class="mb-3">Emergency Contact Details</h4>
          <v-text-field
            type="text"
            label="Emergency Email"
            aria-label="Emergency Email Field"
            class="pt-2 pb-0"
            v-model="emergencyEmail.address"
            prepend-icon="email"
          />
        </div>
        -->

        <div class="extra-functions mb-5">
          <h4 class="mb-3">
            Extra Functions
          </h4>
          <v-select
            type="text"
            label="Select up to 10"
            aria-label="Extra functions. Select Up to 10"
            class="pt-2 pb-0"
            :items="extraFunctions"
            v-model="profile.extraFunctions"
            multiple
            hide-details
            @change="restrictExtra"
            :menu-props="{ top: true, offsetY: true }"
            item-value="optionValue"
            item-text="optionLabel"
            deletable-chips
            chips
          />
        </div>
      </v-col>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import appBarMobile from "@/components/appBarMobile";
import directoryApi from "../api/directory";
import { DatePicker } from "@nswdoe/doe-ui-core";
const UpdateProfile = {
  name: "UpdateProfile",
  components: {
    DatePicker,
    appBarMobile
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      settings: "settings",
      tokens: "tokens",
      loginProfile:'loginProfile'
    }),
    preferNameRules() {
      let toRet = [this.rules.alphaNumeric, this.rules.noNumbers]
      return toRet
    },
    profile:{
      get(){
      return this.loginProfile;
      },
      set(val){
        return val
      }
    },
    secondaryPhone() {
      return this.findContact("Secondary"); // doesnt exist in api, left it here as an example
    },
    businessAddress() {
      return this.findContact("Business", "Address");
    },
    emergencyEmail() {
      return this.findContact("Emergency", "Email");
    },
    firstAssistant() {
      let tAssist = { name: "", email: "", mobile: "", phone: { number: "" } }
      if (this.loginProfile.assistants?.length >= 1)
        tAssist = this.loginProfile.assistants[0]
      if (!tAssist.phone) tAssist.phone = { number: " " }
      if (tAssist.phone.number == " " || tAssist.phone.number == "0400000000")
        tAssist.phone.number = " "
      return tAssist
    },
    workPhone() {
      let workPhone = this.findContact("Work");
      if (!workPhone.number || workPhone.number == "0400000000")
           workPhone.number = ""
      return workPhone
    },
    mainPhone() {
      let workPhone = this.findContact();
      if (!workPhone.number || workPhone.number == "0400000000")
          workPhone.number = "";
      return workPhone
    }
  },
  methods: {
    saveInformation() {
      let updateInfo = this.getPatchModelData();
      let tokens = this.$store.getters.tokens;
      directoryApi.patchProfile(tokens, tokens.user_name, updateInfo).then(
        () => {
          let errorObj = {
            errorStatus: true,
            errorMessage: "Successfully saved your information.",
            heading: "Success"
          };
          this.$store.commit("setAppError", errorObj);
        },
        () => {
          let errorObj = {
            errorStatus: true, 
            errorMessage: "Something went wrong, Please try again later."
          };
          this.$store.commit("setAppError", errorObj);
        }
      );
    },
    getPatchModelData() {
      let model = {
        assistantName: this.firstAssistant.name,
        assistantPhone: this.firstAssistant.phone.number,
        company: this.businessAddress.company,
        department: this.businessAddress.department,
        displayNameSuffix: this.profile.displayNameSuffix ? this.profile.displayNameSuffix : undefined,
        emergencyCountry: undefined,
        emergencyMessage: undefined,
        emergencyStreetAddress: undefined,
        emergencySuburb: undefined,
        extraFunctions: this.profile.extraFunctions?.join(","),
        personalTitle: this.profile.title ? this.profile.title : undefined,
        physicalSite: undefined,
        preferredGivenName: this.profile.preferredGivenName,
        preferredSurname: this.profile.preferredSurname,
        title: this.profile.position_title,
        voipExtension: undefined,
        workMobile: this.mainPhone.number ? this.mainPhone.number : '0400000000',
        workPhone: this.workPhone.number,
        workPostCode: this.businessAddress.postcode ? this.businessAddress.postcode : undefined,
        workStreetAddress: this.businessAddress.streetAddress,
        workSuburb: this.businessAddress.locality
      };
      if (/^ *$/.test(model.preferredGivenName))
        model.preferredGivenName = this.profile.givenName ? this.profile.givenName : ""
      if (/^ *$/.test(model.preferredSurname))
        model.preferredSurname = this.profile.surname ? this.profile.surname :  ""        
      return model;
    },
    restrictExtra() {
      if (this.profile.extraFunctions.length > 10) {
        this.profile.extraFunctions.pop();
      }
    },
    findContact(type = "Main", detailType = "Phone") {
      if (this.profile.contactDetails?.length >= 1) {
        for (let v of this.profile.contactDetails) {
          if (v.contactDetailType == detailType && v.type == type) {
            return v;
          }
        }
      }
      if (detailType == "Phone")
        return {
          type: type,
          contactDetailType: detailType,
          address: "",
          number: ""
        };
      if (detailType == "Address")
        return {
          contactDetailType: detailType,
          type: type,
          country: "",
          locality: "",
          postcode: "",
          stateProvince: "",
          streetAddress: ""
        };
      if (detailType == "Email")
        return { contactDetailType: detailType, type: type, address: "" };
      return null;
    }
  },
  data() {
    return {
    titles: [
          { value: "Mr", text: "Mr" },
          { value: "Ms", text: "Ms" },
          { value: "Miss", text: "Miss" },
          { value: "Mx", text: "Mx" },
          { value: "Mrs", text: "Mrs" },
          { value: "Prof", text: "Prof (Professor)" },
          { value: "Dr", text: "Dr (Doctor)" }
        ],
      genders: [
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
        { text: "Other", value: "O" }
      ],
      rules: {
        required: value => !!value || "Please fill out this field.",

        phoneIsValid: val => {
          let tnum = val.split(" ").join("");
          if (/[^0-9 +]/.test(tnum))
            return "Mobile Phone number cannot contain non-number characters.";
          if (tnum === "") return true;
          if (tnum.length == 10 || tnum.length == 13) return true;
          return "Must be a valid mobile phone.";
        },

        alphaNumeric: val => {
          if (val === "" || val === " ") return true;
          if (/[^A-Za-z0-9\s]+/.test(val))
            return "Cannot contain special characters (eg. !,.@%#).";
          return true;
        },
        onlyNumbers: (val) => {
          if (val === "" || val === " ") return true
          if (/[^0-9\s]+/.test(val)) return "Can only contain numbers."
          return true
        },
        noNumbers: val => {
          if (val === "" || val === " ") return true;
          if (/[0-9]+/.test(val)) return "Cannot contain numbers (0-9).";
          return true;
        },
        lessThan255: val => {
          if (val && val.length > 255) return "Cannot be more than 255 characters.";
          return true;
        },
        lessThan64: val => {
          if (val && val.length > 64) return "Cannot be more than 64 characters.";
          return true;
        },
        lessThan126: val => {
          if (val && val.length > 126) return "Cannot be more than 126 characters.";
          return true;
        }
      }
    };
  },
  props: {
    displayDob: {
      type: Boolean,
      default: false
    },
    extraFunctions: {
      type: Array,
      default: function() {
        return [
          {
            optionLabel: "Access Asia Coordinator",
            optionValue: "Access_Asia_Coordinator"
          },
          {
            optionLabel: "Advanced First Aid Officer",
            optionValue: "Advanced_First_Aid_Officer"
          },
          {
            optionLabel: "Anti Racism Contact Officer",
            optionValue: "Anti_Racism_Contact_Officer"
          },
          { optionLabel: "Careers Adviser", optionValue: "Careers_Adviser" },
          {
            optionLabel: "Chief Fire Warden",
            optionValue: "Chief_Fire_Warden"
          },
          {
            optionLabel: "Computer Coordinator",
            optionValue: "Computer_Coordinator"
          },
          {
            optionLabel: "Coordinator OASIS Systems",
            optionValue: "Coordinator_OASIS_Systems"
          },
          {
            optionLabel: "Coordinator Web Services",
            optionValue: "Coordinator_Web_Services"
          },
          { optionLabel: "Counsellor", optionValue: "Counsellor" },
          {
            optionLabel: "Course Information Officer",
            optionValue: "Course_Information Officer"
          },
          {
            optionLabel: "Directory Administrator",
            optionValue: "Directory_Administrator"
          },
          {
            optionLabel: "Enrolments Officer",
            optionValue: "Enrolments_Officer"
          },
          {
            optionLabel: "Federation Representative",
            optionValue: "Federation_Representative"
          },
          { optionLabel: "Fire Warden", optionValue: "Fire_Warden" },
          {
            optionLabel: "First Aid Officer",
            optionValue: "First_Aid Officer"
          },
          { optionLabel: "Floor Warden", optionValue: "Floor_Warden" },
          { optionLabel: "HSLO", optionValue: "HSLO" },
          {
            optionLabel: "Home School Liaison Officer",
            optionValue: "Home_School Liaison Officer"
          },
          {
            optionLabel: "Information Technology Coordinator",
            optionValue: "Information_Technology Coordinator"
          },
          {
            optionLabel: "International Student Coordinator",
            optionValue: "International_Student Coordinator"
          },
          { optionLabel: "JP", optionValue: "JP" },
          {
            optionLabel: "Justice of the Peace",
            optionValue: "Justice_of the Peace"
          },
          { optionLabel: "LMS Coordinator", optionValue: "LMS_Coordinator" },
          { optionLabel: "OH Chairperson", optionValue: "OH_Chairperson" },
          { optionLabel: "OH Committee", optionValue: "OH_Committee" },
          {
            optionLabel: "OH Representative",
            optionValue: "OH_Representative"
          },
          {
            optionLabel: "Occupational First Aid Officer",
            optionValue: "Occupational_First Aid Officer"
          },
          { optionLabel: "PSA Delegate", optionValue: "PSA_Delegate" },
          {
            optionLabel: "Peer Mediation Coordinator",
            optionValue: "Peer_Mediation Coordinator"
          },
          {
            optionLabel: "Prescribed Medication Officer",
            optionValue: "Prescribed_Medication Officer"
          },
          {
            optionLabel: "Primary Principal Council Executive",
            optionValue: "Primary_Principal Council Executive"
          },
          {
            optionLabel: "Properties Supervisor",
            optionValue: "Properties_Supervisor"
          },
          {
            optionLabel: "Reading Recovery",
            optionValue: "Reading_Recovery"
          },
          {
            optionLabel: "School Counsellor",
            optionValue: "School_Counsellor"
          },
          {
            optionLabel: "Senior First Aid Officer",
            optionValue: "Senior_First Aid Officer"
          },
          {
            optionLabel: "Special Education",
            optionValue: "Special_Education"
          },
          {
            optionLabel: "Spokeswomen Representative",
            optionValue: "Spokeswomen_Representative"
          },
          {
            optionLabel: "Sports Coordinator",
            optionValue: "Sports_Coordinator"
          },
          {
            optionLabel: "Student Association Officer",
            optionValue: "Student_Association Officer"
          },
          {
            optionLabel: "Support Teacher Behaviour",
            optionValue: "Support_Teacher Behaviour"
          },
          {
            optionLabel: "Support Teacher Transition",
            optionValue: "Support_Teacher Transition"
          },
          { optionLabel: "TAFE Online", optionValue: "TAFE_Online" },
          {
            optionLabel: "Teacher Librarian",
            optionValue: "Teacher_Librarian"
          },
          { optionLabel: "VET Coordinator", optionValue: "VET_Coordinator" },
          { optionLabel: "Web Hosting", optionValue: "Web_Hosting" },
          { optionLabel: "Web Services", optionValue: "Web_Services" },
          {
            optionLabel: "Web Services Implementation",
            optionValue: "Web_Services Implementation"
          },
          { optionLabel: "Year 10 Advisor", optionValue: "Year_10 Advisor" },
          { optionLabel: "Year 11 Advisor", optionValue: "Year_11 Advisor" },
          { optionLabel: "Year 12 Advisor", optionValue: "Year_12 Advisor" },
          { optionLabel: "Year 9 Advisor", optionValue: "Year_9 Advisor" },
          { optionLabel: "Year Advisor", optionValue: "Year_Advisor" },
          { optionLabel: "Year Patron", optionValue: "Year_Patron" },
          { optionLabel: "eLAPS", optionValue: "eLAPS" }
        ];
      }
    }
  }
};
export default UpdateProfile;
</script>

<style scoped>
</style>